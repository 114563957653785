@import "./variables";

.m-p-zero {
    margin: 0px !important;
    padding: 0px !important;
}

.actionIcons {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.flex_center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.inline_flex {
    display: inline-flex !important;
}

.gap_normal {
    gap: 0.5rem;
}

.text_center {
    text-align: center !important;
}

.cursor_none {
    cursor: not-allowed !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.default_label {
    text-align: left;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 30px;
    color: $label-color;
}

.big-calendar {
    width: 100%;
    border-radius: $most-used-border-radius;
    background: $color-text-invert;
    box-shadow: $box-border-shadow;
    // height: 310px;
    flex-shrink: 0;
    padding: 10px 10px;
}

.festival-calendar {
    width: 100%;
    border-radius: 20px;
    background: $color-text-invert;
    box-shadow: none;
    // height: 310px;
    flex-shrink: 0;
    padding: 10px 10px;
}

.calendar_title {
    color: $color-navigation;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
}

.week-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center !important;
}

.calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 2fr);
    text-align: center;
    line-height: 39px;
}

.date {
    width: calc(100% / 7);
    height: calc(100% / 6);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 11px;
}

.text-color {
    color: #07f;
}

.scroll-month-arrow {
    margin: 0 8%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $color-text-invert;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.css-1u9des2-indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 1px !important;
    background-color: hsla(0, 0%, 80%, 0);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}

/* Scrollbar styles */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    flex-shrink: 0;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 50px;
    background: #eff6fe;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #0077ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0052b4;
}

.sliderFill {
    &:hover {
        cursor: pointer;
    }
}

.submit-button {
    padding: 0.4rem 4rem;
    border-radius: 10px;
    background: #3660f8;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    border: none;
}

.edit-form-header {
    background-color: $color-navigation;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 240px;
    height: 40px;
    margin: 5px auto;
    color: $color-text-invert;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
}

.radio_button .form-check-input {
    height: 22px;
    width: 22px;
    background-color: $color-text-invert;
    border: $color-navigation 2px solid;
    padding: 2px;
}

.radio_button .form-check-label {
    font-size: 18px;
}

.radio_button .form-check-input:checked {
    height: 22px;
    width: 22px;
    background-color: #ffffff !important;
    background-image: url("../../assets/images/Accounts/dot_filter.svg") !important;
    background-size: 16px 16px !important;
    border: $color-navigation 2px solid !important;
    padding: 2px !important;
}
